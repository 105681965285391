import { PillsRowStoryblok, PillStoryblok } from '@/shared/types/storyblok/storyblok-types';
import styles from './ProjectShowcase.module.scss';
import Container from '../Container/Container';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { useRef, useEffect, useState } from 'react';
import { Typography } from '@/shared';
import { Icon } from '@/shared/ui/Icon/Icon';
import { SBImage } from '../ImageService/SBImage';
import clsx from 'clsx';
import Link from 'next/link';
import { storyblokEditable } from '@storyblok/react';

const ProjectShowcase = ({ blok }: PillsRowStoryblok) => {
    const { project, title, image, pretitle, link } = blok;

    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['start center', 'center center', 'end center'],
    });

    const backgroundColor = useTransform(scrollYProgress, [0, 0.5], ['rgba(217, 71, 22, 0)', 'rgba(217, 71, 22, 1)']);
    const translateX = useTransform(scrollYProgress, [0, 0.5], ['50%', '0%']);
    const translateY = useTransform(scrollYProgress, [0, 0.5], ['150%', '0%']);
    const width = useTransform(scrollYProgress, [0, 0.5], ['0%', '45%']);
    const opacity = useTransform(scrollYProgress, [0, 0.5], ['0%', '100%']);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const unsubscribe = scrollYProgress.onChange(value => {
            if (value >= 0.3) {
                setIsActive(true);
            } else {
                setIsActive(false);
            }
        });

        return () => unsubscribe();
    }, [scrollYProgress]);

    return (
        <div {...storyblokEditable(blok)} ref={ref} className={clsx(styles.root, { [styles.isActive]: isActive })}>
            <Link href={project?.full_slug || link?.cached_url || window?.location}>
                <motion.div className={styles.motion} style={{ backgroundColor }}>
                    {/* DESKTOP */}
                    <div className={styles.desktop}>
                        <Container code="large" className={{ wrap: styles.wrap }}>
                            <motion.div
                                className={styles.text}
                                initial={{
                                    x: '50%',
                                }}
                                style={{ x: translateX }}
                            >
                                <Typography.H4 className={clsx(styles.pretitle)}>
                                    {project?.content?.clientName || pretitle}
                                </Typography.H4>
                                <div className={styles.titleWrapper}>
                                    <Typography.H2 className={clsx(styles.title)}>
                                        {project?.content?.title || title}
                                    </Typography.H2>
                                    <div className={styles.icon} style={{ marginTop: '10px' }}>
                                        <Icon name="arrow_forward" />
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div
                                className={styles.imageDesktop}
                                initial={{
                                    x: '100%',
                                    width: '0',
                                    opacity: 0,
                                }}
                                style={{ x: translateX, opacity: opacity, width: width }}
                            >
                                <SBImage image={project?.content?.mainImage || image} width={500} fit={'cover'} />
                            </motion.div>
                        </Container>
                    </div>
                    {/* MOBILE */}
                    <div className={styles.mobile}>
                        <Container code="large" blok={blok} className={{ wrap: styles.wrap }}>
                            <motion.div className={styles.text}>
                                <Typography.H4 className={clsx(styles.pretitle)}>
                                    {project?.content?.clientName || pretitle}
                                </Typography.H4>
                                <div className={styles.titleWrapper}>
                                    <Typography.H2 className={clsx(styles.title)}>
                                        {project?.content?.title || title}
                                    </Typography.H2>
                                    <div className={styles.icon} style={{ marginTop: '10px' }}>
                                        <Icon name="arrow_forward" />
                                    </div>
                                </div>
                            </motion.div>
                            <motion.div
                                className={styles.image}
                                initial={{
                                    opacity: 0,
                                    y: '150%',
                                }}
                                style={{ y: useSpring(translateY), opacity: opacity }}
                            >
                                <SBImage image={project?.content?.mainImage || image} height={300} fit={'cover'} />
                            </motion.div>
                        </Container>
                    </div>
                </motion.div>
            </Link>
        </div>
    );
};

export default ProjectShowcase;
